<template>
  <div class="eventdownload-spinner">
    <img
            src="@/assets/images/spinner-beige.gif"
            alt="loading"
            class="spinner"
          />
  </div>
</template>
<script lang="ts">
export default {
  name: "LoadingIndicator",
  setup() {},
};
</script>
