<template>
  <!-- Modal -->
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        :class="[
          'modal-container',
          props.size === 'm' ? 'modal-medium' : '',
          props.size === 'l' ? 'modal-large overflow-auto' : '',
        ]"
      >
        <div class="modal-header d-flex justify-content-center">
          <slot name="header"></slot>
        </div>
        <Form @submit="onSubmit(null)">
          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="w-100 d-flex justify-content-between">
                <slot v-if="props.okonly">
                  <button
                    class="btn btn-sm btn-primary"
                    @click="$emit('cancel')"
                    id="modal-cancel"
                  >
                    OK
                  </button>
                </slot>
                <slot v-else>
                  <button
                    class="btn btn-sm btn-light"
                    @click="$emit('cancel')"
                    id="modal-cancel"
                  >
                    Cancel
                  </button>
                  <button
                    :class="[
                      'btn',
                      'btn-sm',
                      'btn-modal',
                      props.delete ? 'btn-danger' : 'btn-primary',
                    ]"
                    @click="onSubmit($event)"
                    v-if="!props.form"
                  >
                    <span v-if="!props.delete && !props.crmsync && !props.restore"
                      >OK</span
                    >
                    <span v-else-if="props.crmsync">SYNC</span>
                    <span v-else-if="props.restore">RESTORE</span>
                    <span v-else>DELETE</span>
                  </button>
                  <button
                    v-else
                    type="submit"
                    :class="[
                      'btn',
                      'btn-sm',
                      'btn-modal',
                      props.delete ? 'btn-danger' : 'btn-primary',
                    ]"
                  >
                    OK
                  </button>
                </slot>
              </div>
            </slot>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "Modal",
  components: { Form, Field, ErrorMessage },
  props: ["size", "form", "delete", "crmsync", "restore", "okonly"],
  setup(props, { emit }) {
    function onSubmit(event) {
      //Nexttick doesn't work so have to hardcode a pause after ui update.
      //setTimeout(function () {
      emit("confirm", event);
      // }, 500);
    }
    return {
      props,
      onSubmit,
    };
  },
};
</script>
