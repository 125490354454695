<template>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm">
    <div class="container d-flex justify-content-center">
        <div class="col-4 d-flex align-items-center">
          <a href="https://www.petersco.com/" class="navbar-brand">
            <img src="@/assets/images/PetersLogo.png" class="nav-logo" alt="" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
        <div class="col-8 d-flex justify-content-end align-items-center">
          <div class="p-2">
            <ul class="nav navbar-nav flex-row float-right">
              <li v-if="isLoggedIn" class="nav-item dropdown d-none d-md-block">
                <a
                  class="nav-link nav-link-welcome dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Welcome back, {{ userName }}
                </a>

                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link class="dropdown-item" to="/changepassword"
                    >Change Password</router-link
                  >
                  <li>
                    <a class="dropdown-item clickable" @click="signOut"
                      >Log Out</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
    </div>
  </nav>

  <div class="bg-beige" id="menuBar">
    <div class="container">
      <div class="navbar-collapse collapse d-sm-flex">
        <ul class="nav navbar-nav nav-flex">
          <li class="nav-item" v-if="canViewDocs">
            <router-link class="nav-link top-menu" to="/documents"
              >Documents</router-link
            >
          </li>
          <li class="nav-item" v-if="canSendEmail">
            <router-link class="nav-link top-menu" to="/emailcomposer"
              >Energy Facts</router-link
            >
          </li>
          <li class="nav-item" v-if="canAccessCRM">
            <div class="nav-link top-menu clickable" @click="showCrmModal = true">
              CRM Sync
            </div>
          </li>
          <li class="nav-item" v-if="canViewUserActivity">
            <router-link class="nav-link top-menu" to="/useractivity"
              >User Activity</router-link
            >
          </li>
          <li
            class="nav-item dropdown"
            v-if="
              canViewEmailTemplates ||
              canViewUsers ||
              canViewRoles ||
              canViewCompanies ||
              canViewSectors ||
              canViewPublicationTypes ||
              canViewEventDownloads
            "
          >
            <a
              class="nav-link top-menu dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Administration
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                v-if="canViewEmailTemplates"
                class="dropdown-item"
                to="/emailtemplates"
                >Email Templates</router-link
              >
              <router-link v-if="canViewUsers" class="dropdown-item" to="/users"
                >User Management</router-link
              >
              <router-link
                v-if="canViewCompanies"
                class="dropdown-item"
                to="/companies"
                >Company Management</router-link
              >
              <router-link v-if="canViewRoles" class="dropdown-item" to="/roles"
                >Roles</router-link
              >
              <router-link
                v-if="canViewSectors"
                class="dropdown-item"
                to="/sectors"
                >Sectors</router-link
              >
              <router-link
                v-if="canViewPublicationTypes"
                class="dropdown-item"
                to="/publicationtypes"
                >Publication Types</router-link
              >

              <router-link
                v-if="canViewHolidays"
                class="dropdown-item"
                to="/holidays"
                >Holidays</router-link
              >

              <router-link
                v-if="canViewEventDownloads"
                class="dropdown-item"
                to="/eventDownloads"
                >Event Downloads</router-link
              >
            </ul>
          </li>
          <li class="nav-item dropdown d-md-none d-sm-block" v-if="isLoggedIn">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Profile
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" to="/changepassword"
                >Change Password</router-link
              >
              <li>
                <a class="dropdown-item clickable" @click="signOut">Log Out</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <transition name="modal">
    <modal
      v-if="showCrmModal"
      @cancel="showCrmModal = false"
      @confirm="confirmCrmSync()"
      :crmsync="true"
    >
      <template v-slot:header>
        <div class="title">Confirm Sync</div>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-center">
          <div class="message">
            Are you sure you want to manually sync with Sugar CRM?
          </div>
        </div>
      </template>
    </modal>
  </transition>
</template>
<script lang="ts">
import { ref, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { IMessage, IUser } from "@/store/common/Types";
import modal from "@/components/Modal.vue";
import { debounce } from "ts-debounce";

export default {
  name: "NavBar",
  components: {
    modal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isUserAuthenticated);
    const userName = computed(() =>
      isLoggedIn ? store.getters.currentUser.firstName : ""
    );
    const canViewDocs = computed(() => store.getters.userCanViewDocuments);
    const canViewUsers = computed(() => store.getters.userCanViewUsers);
    const canSendEmail = computed(() => store.getters.userCanSendEmail);
    const canAccessCRM = computed(() => store.getters.userSyncCRM);
    const canViewUserActivity = computed(() => store.getters.userActivityView);
    const canViewSectors = computed(
      () =>
        store.getters.userCanCreateSectors || store.getters.userCanEditSectors
    );
    const canViewPublicationTypes = computed(
      () =>
        store.getters.userCanCreatePublicationTypes ||
        store.getters.userCanEditPublicationTypes
    );
    const canViewRoles = computed(
      () => store.getters.userCanCreateRoles || store.getters.userCanEditRoles
    );
    const canViewCompanies = computed(
      () =>
        store.getters.userCanCreateCompanies ||
        store.getters.userCanEditCompanies
    );
    const canViewEmailTemplates = computed(
      () =>
        store.getters.userCanCreateEmailTemplates ||
        store.getters.userCanEditEmailTemplates
    );
    const canViewHolidays = computed(
      () =>
        store.getters.userCanCreateHolidays || store.getters.userCanEditHolidays
    );
    const canViewEventDownloads = computed(
      () =>
        store.getters.userCanEditEventDownload
    );

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "login" }));
    };

    let showCrmModal = ref(false);

    const debouncedConfirmCrmSync = debounce(async () => {
      let message: IMessage = {
        queueName: "sugarcrmsync",
        message: { userId: (store.getters.currentUser as IUser).userId },
      };
      await store.dispatch(Actions.CREATE_MESSAGE, message);
      showCrmModal.value = false;
    });

    async function confirmCrmSync() {
      debouncedConfirmCrmSync();
    }

    return {
      isLoggedIn,
      userName,
      signOut,
      canViewDocs,
      canViewUsers,
      canSendEmail,
      canAccessCRM,
      canViewUserActivity,
      canViewSectors,
      canViewPublicationTypes,
      canViewRoles,
      canViewCompanies,
      canViewEmailTemplates,
      canViewHolidays,
      showCrmModal,
      confirmCrmSync,
      canViewEventDownloads
    };
  },
};
</script>
