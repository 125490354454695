<template>
  <header>
    <NavBar />
  </header>
  <LoadingIndicator />
  <main role="main">
    <router-view v-slot="{ Component }">
      <Suspense timeout="0">
        <div>
          <component :is="Component" />
        </div>
      </Suspense>
    </router-view>
  </main>
  <vfooter />
</template>

<script lang="ts">
import NavBar from "@/components/NavBar.vue";
import vfooter from "@/components/Footer.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default {
  name: "app",
  components: {
    NavBar,
    vfooter,
    LoadingIndicator,
  },
};
</script>
