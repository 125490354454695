import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/cipf.svg'
import _imports_1 from '@/assets/images/iiroc.svg'


const _hoisted_1 = { class: "footer d-flex flex-fill" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"container d-flex flex-wrap\"><div class=\"footer-main d-flex flex-column col-lg-6 col-md-12\"><div class=\"text my-2\"> The <i>independent</i> investment bank that visionaries have counted on for over half a century </div><div class=\"footer-links\"><span><a href=\"https://peterscoweb.azurewebsites.net/contact\" target=\"_blank\">CONTACT</a></span><span class=\"footer-separator\"></span><span><a href=\"https://peterscoweb.azurewebsites.net/legalcompliance/#TermsofUse\" target=\"_blank\">TERMS OF USE</a></span><span class=\"footer-separator\"></span><span><a href=\"https://peterscoweb.azurewebsites.net/legalcompliance/#ConflictsofInterest\" target=\"_blank\">CONFLICTS OF INTEREST</a></span><span class=\"footer-separator\"></span><span><a href=\"https://peterscoweb.azurewebsites.net/legalcompliance\" target=\"_blank\">LEGAL &amp; COMPLIANCE</a></span></div><div class=\"address my-2\"> Peters &amp; Co. Limited<br><a href=\"https://goo.gl/maps/AM1W5XuXgxVddw4C7\" target=\"_blank\">2300 Jamieson Place, 308 Fourth Avenue<br> SW Calgary, Alberta (Canada) T2P 0H7</a> <br><a href=\"tel:+14032614850\">403.261.4850</a> <a href=\"mailto:info@petersco.com\">info@petersco.com</a></div></div><div class=\"address my-2 d-flex flex-wrap\"><div class=\"logo\"><a href=\"https://www.cipf.ca/\" target=\"_blank\"><img src=\"" + _imports_0 + "\" class=\"cipf-icon m-3\" alt=\"\"></a></div><div class=\"logo\"><div class=\"iiroc-logo\"><a href=\"https://www.iiroc.ca/\" target=\"_blank\"><img src=\"" + _imports_1 + "\" class=\"iiroc-icon\" alt=\"\"></a></div><div class=\"iiroc-link\"><a href=\"https://www.iiroc.ca/investors/know-your-advisor-iiroc-advisor-report\" target=\"_blank\">Generate an IIROC <i>AdvisorReport</i></a></div></div></div></div>", 1)
  ])))
}